.mobile-welcome-page-container,
.welcome-page-container {
    padding-bottom: 35px;
    background-color: hsl(225, 29%, 90%);
}

.welcome-page-container {
    min-width: 1100px;
}

.mobile-welcome-page-section-left-side-container {
    padding-top: 35px;
}

.mobile-welcome-page-section-left-side-container,
.welcome-page-section-left-side-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 35px;
}


.welcome-page-section-left-side-buttons-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.welcome-page-section-left-side-button {
    background-color: hsl(227, 14%, 60%);
    color: white;
    font-size: 16px;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
}

.mobile-welcome-page-section-left-side-title {
    font-size: 35px;
    font-weight: 800;
}

.welcome-page-section-left-side-title {
    font-size: clamp(32px, 8.5vmin, 70px);
    font-weight: 800;
}

.mobile-welcome-page-section-left-side-subtitle {
    font-size: 15px;
}

.welcome-page-section-left-side-subtitle {
    font-size: 20px;
}

.welcome-page-section-right-side-container {
    height: 100%;
    width: 100%;
}

.welcome-page-section-image-container {
    width: 100%;
    height: 100%;
}

.welcome-page-section-image {
    inset: 0;
    margin: auto;
    height: fit-content;
    min-height: 250px;
}

.welcome-page-section-third-image {
    border-radius: 10px;
}

.welcome-page-first-section-left-side-button {
    background-color: hsl(190, 72%, 35%);
}

.welcome-page-second-section-left-side-button {
    background-color: transparent;
    border: 1px solid white;
    color: white;
}

.welcome-page-first-section-left-side-button:hover {
    background-color: hsl(190, 72%, 30%);
}

.welcome-page-second-section-left-side-button:hover {
    background-color: hsl(214, 22%, 55%);
}

.welcome-login-button {
    color: black;
    background-color: #f0f2e9;
    font-size: 17px;
}

.welcome-login-button:hover {
    background-color: hsl(73, 26%, 83%);
}

.welcome-login-button:active {
    background-color: hsl(73, 26%, 78%);
}

.welcome-signup-button {
    color: white;
    background-color: rgb(30, 35, 48);
    font-size: 17px;
}

.welcome-signup-button:hover {
    background-color: hsl(223, 23%, 25%);
}

.welcome-signup-button:active {
    background-color: hsl(223, 23%, 30%);
}