.image-dialog-image-container {
    width: 100vw;
    height: 100vh;
    max-width: 900px;
    max-height: 900px;
    pointer-events: none;
}

.image-dialog-close-button {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 30px;
    top: 50px;
    cursor: pointer;
    z-index: 60;
}

.image-dialog-close-button > path {
    stroke: white;
}