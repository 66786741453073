.shopify-help-page-container {
    background-color: hsl(165, 3%, 90%);
    margin: 20px;
    padding: 25px 5%;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
}

.shopify-help-page-title {
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
}

.shopify-help-page-section-title {
    text-align: start;
    font-size: 16px;
    width: 100%;
    font-weight: 350;
    margin-top: 20px;
    margin-bottom: 10px;
}

.shopify-help-page-section-image {
    max-width: 1200px;
    max-width: 100%;
    margin: 10px;
}
.shopify-help-page-section-image-container {
    display: flex;
    justify-content: center;
}