
.loader {
    width: 30px;
    height: 30px;
    border: 2px solid;
    border-top-color: transparent;
    border-left-color: black;
    border-right-color: black;
    border-bottom-color: black;
    border-radius: 50%;
    animation: spin 750ms infinite linear;
}

@keyframes spin {
    from {
        rotate: 0deg;
    }
    to {
        rotate: 360deg;
    }
}