.dashboard-topbar-container,
.mobile-dashboard-topbar-container {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 50;
}

.mobile-dashboard-topbar-paylink-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    height: 60px;
    padding: 0 15px;
}

.mobile-dashboard-topbar-content {
    padding: 10px 10px 0 10px;
    border-bottom: 1px solid #efefef;
}

.mobile-dashboard-topbar-paylink-logo-container {
    display: flex;
    align-items: center;
    gap: 15px;
}

.mobile-dashboard-topbar-paylink-logo {
    width: 35px;
    height: 35px;
}

.mobile-dashboard-topbar-create-new-link-button {
    width: 20px;
    height: 20px;
}

.mobile-dashboard-topbar-create-new-link-button:active {
    background-color: hsl(0, 0%, 90%);
}

.dashboard-topbar-content {
    height: var(--dashboard-topbar-height);
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.dashboard-topbar-bottom-line {
    background-color: #cccccc;
    height: 1px;
    width: 100%;
}

.dashboard-topbar-image {
    width: 100px;
    aspect-ratio: 5 / 2;
}

.dashboard-topbar-buttons-container {
    display: flex;
    align-items: center;
    gap: 20px;
}

.mobile-dashboard-topbar-buttons-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 8px 8px 0 8px;
}

.mobile-dashboard-topbar-button-image {
    width: 27px;
    height: 27px;
}

.dashboard-button,
.mobile-dashboard-button {
    color: hsl(69, 7%, 40%);
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.dashboard-button {
    gap: 10px;
    font-size: 14px;
    padding: 10px 20px;
    border-radius: 10px;
}

.dashboard-button.disabled {
    opacity: 0.3;
    cursor: none;
    pointer-events: none;
}

.mobile-dashboard-button {
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    padding: 5px 10px;
    height: 60px;
    border-bottom: 2px solid transparent;
}

.mobile-dashboard-topbar-links-button-label {
    font-size: 13px;
    white-space: pre-line;
    text-align: center;
    width: 100%;
}

.dashboard-button.disabled,
.mobile-dashboard-button.disabled {
    color: hsl(69, 7%, 80%);
    cursor: none;
    pointer-events: none;
}

.dashboard-button.selected {
    color: black;
}

.mobile-dashboard-button.selected {
    color: black;
    border-bottom: 2px solid black;
}

.dashboard-button:hover {
    background-color: #F0F0F0;
}

.dashboard-topbar-button-image {
    width: 20px;
    height: 20px;
}

.mobile-dashboard-topbar-more-options-drawer-option {
    text-align: start;
    padding: 15px;
    cursor: pointer;
    position: relative;
    text-transform: capitalize;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 20px;
}

.mobile-dashboard-topbar-more-options-drawer-option:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.mobile-dashboard-topbar-more-options-drawer-option::after {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    background-color: #cccccc;
    width: 100%;
    content: "";
}

.mobile-dashboard-topbar-more-options-drawer-option:active {
    background-color: hsl(0, 0%, 90%);
}

.mobile-dashboard-topbar-more-options-drawer-option.disabled {
    color: hsl(69, 7%, 80%);
    cursor: none;
    pointer-events: none;
}

.mobile-dashboard-topbar-more-options-drawer-option-logout {
    color: red;
}