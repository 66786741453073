.store-settings-link-style-section-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.store-settings-link-style-section-title {
    color: #81828e;
    font-size: 15px;
    font-weight: 600;
}

.store-settings-link-style-section-content {
    padding: 10px;
}