.boxed-message-container {
    font-size: 14px;
    padding: 10px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 15px;
}

.boxed-message-container.error {
    background-color: #ffdce0;
    color: #d74454;
    border: 1px solid #feb1bb;
}

.boxed-message-container.info {
    background-color: #89CFF0;
    color: #0066b2;
    border: 1px solid #7CB9E8;
}

.boxed-message-text {
    text-align: start;
}

.boxed-message-image {
    width: 40px;
    height: 40px;
}