.mobile-account-page-section,
.account-page-section {
    --section-title-color: #525876;
    --subsection-input-background-color: #F9FBFC;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    gap: 30px;
    padding: 10px;
    position: relative;
}

.account-page-section {
    padding: 20px 10px 10px 10px;
}

.mobile-account-page-section {
    width: 100%;
    padding-bottom: 30px;
}

.mobile-account-page-section::after {
    content: "";
    display: block;
    height: 1px;
    background-color: var(--subsection-title-color);
    width: 100vw;
    position: absolute;
    left: -10px;
    bottom: 0;
}

.mobile-account-page-section:last-child::after {
    content: "";
    display: none;
}

.account-page-section-content {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
}

.account-page-section-title {
    text-align: start;
    font-size: 17px;
    width: 100%;
    font-weight: 500;
    color: #525876;
}