.paydin-dialog {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    outline: 0;
    gap: 35px;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    text-align: start;
}

.paydin-dialog-content {
    width: 100%;
    text-align: center;
}

.paydin-dialog-title {
    margin: 25px 0;
}

.close-dialog-image {
    position: absolute;
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.paydin-dialog-message {
    white-space: pre-line;
}

.paydin-dialog-buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 25px;
}

.general-dialog-button {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
}

.dialog-button-primary {
    padding: 10px 0px;
    background-color: black;
    color: white;
    letter-spacing: 1px;
}

.dialog-button-secondary { 
    padding: 10px 0px;
    border: 1.5px solid black;
    color: black;
    background-color: white;
    letter-spacing: 0.5px;
}

.dialog-button-underlined {
    text-align: center;
    color: black;
}