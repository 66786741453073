.analytics-page-title {
    font-size: 28px;
    font-weight: 600;
}

.mobile-analytics-page-container {
    font-size: 22px;
    font-weight: 600;
    padding: 10px;
}

.analytics-page-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.mobile-analytics-page-title-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
}

.analytics-page-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 25px;
}

.mobile-analytics-page-overall-statistics {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 15px;
}

.analytics-page-overall-statistics {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 15px;
}