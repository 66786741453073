.link-analytics-section {
    padding: 10px;
    border: 1.5px solid hsl(228, 23%, 87%);
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
}

.link-analytics-title {
    font-size: 13px;
    font-weight: 300;
}

.link-analytics-value {
    font-size: 21px;
    font-weight: 300;
}