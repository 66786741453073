.create-new-password-page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.create-new-password-page-password-input-button-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.create-new-password-page-title {
    text-align: center;
    text-transform: capitalize;
}

.create-new-password-input-field {
    border: none;
    font-size: 15px;
    width: 100%;
    outline: none;
    height: 50px;
    background-color: #e2e2e2;
    border-radius: 7px;
    padding: 0 10px;
}

.create-new-password-page-input-button-section {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    text-align: center;
}

.create-new-password-reset-password-button {
    padding: 15px 0;
    margin: 10px;
    border-radius: 30px;
    background-color: black;
    cursor: pointer;
    pointer-events: all;
    text-align: center;
    color: white;
}

.create-new-password-reset-password-button:active {
    background-color: hsl(0, 0%, 20%);
}

.create-new-password-reset-password-button.disabled {
    cursor: none;
    pointer-events: none;
    background-color: #a6a5a5;
}

.create-new-password-input-field-container {
    height: 50px;
    background-color: #e2e2e2;
    border-radius: 7px;
    padding: 0 10px;
    display: flex;
    align-items: center;
}

.create-new-password-page-password-field-show-hide-password-image {
    width: 20px;
    height: 20px;
    cursor: pointer;
}