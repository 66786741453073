.welcome-layout-content {
    padding-top: calc(var(--welcome-topbar-height) + 20px);
}

.welcome-layout-content.topbar-padding-page {
    padding-top: var(--welcome-topbar-height)
}

.welcome-layout-content.mobile-topbar-padding-page {
    padding-top: var(--mobile-welcome-topbar-height)
}

.welcome-layout-content.no-padding-page {
    padding-top: 0;
    height: 100vh;
}