.image-crop-dialog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.image-crop-dialog-topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: calc(100vw - 40px);
    padding: 10px;
}

.image-crop-dialog-topbar-done-button,
.image-crop-dialog-topbar-back-button {
    cursor: pointer;
}

.image-crop-dialog-topbar-done-button {
    color: blue;
}

.image-crop-dialog-topbar-back-button {
    width: 30px;
    height: 30px;
}

.image-crop-dialog-topbar-title {
    font-weight: 600;
    font-size: 17px;
}