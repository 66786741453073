.edit-link-section-input-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    height: 100%;
}

.edit-link-section-input-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.edit-link-section-link-data-input-title-info-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.edit-link-section-input-title {
    font-size: 14px;
    color: #7f859e;
}

.edit-link-section-info-section {
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: hsl(210, 16%, 90%);
    padding: 3px;
    cursor: pointer;
}

.edit-link-section-info {
    width: 10px;
    height: 10px;
    position: absolute;
    inset: 0;
    margin: auto;
}

.edit-link-section-info-section:hover > .edit-link-section-link-data-tooptip-bubble {
    display: block;
}

.edit-link-section-link-data-tooptip-bubble:hover {
    display: block
}

.edit-link-section-link-data-tooptip-bubble {
    display: none;
    font-size: 14px;
    color: #EFF0F3;
    padding: 10px;
    position: absolute;
    background-color: hsl(233, 27%, 25%);
    border-radius: 7px;
    left: 30px;
    top: -10px;
    width: var(--tooltip-bubble-width);
    z-index: 50;
}

.edit-link-section-link-data-tooptip-bubble::before {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    rotate: 45deg;
    position: absolute;
    left: -8px;
    top: 10px;
    padding: 10px;
    background-color: hsl(233, 27%, 25%);
    z-index: -1;
}

.edit-link-section-link-data-input-character-limit {
    font-size: 14px;
    font-weight: 300;
}

.edit-link-section-input-with-prefix,
.edit-link-section-input {
    height: 50px;
    width: 100%;
    border: 1px solid hsl(231, 24%, 90%);
    padding: 5px 10px;
    border-radius: 5px;
    outline: none;
}

.edit-link-section-input-with-prefix {
    height: 35px;
}

.edit-link-section-input::placeholder {
    color: #DCDEE7;
    font-weight: 400;
}

.edit-link-section-link-data-shopping-url-input-row {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.edit-link-section-link-data-shopping-url-input-prefix {
    font-size: 14px;
    flex-shrink: 0;
}

.edit-link-section-link-data-shopping-url-input-container {
    display: flex;
    align-items: start;
    gap: 10px;
}