.paydin-menu-item {
    padding: 15px;
    cursor: pointer;
}

.paydin-menu-item:hover {
    background-color: hsl(0, 0%, 95%);
}

.paydin-menu-item:active {
    background-color: hsl(0, 0%, 90%);
}