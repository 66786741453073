.policies-footer-text {
    text-align: center;
    position: absolute;
    bottom: 15px;
    left: 0;
    font-size: 12px;
    width: 100%;
}

.policies-footer-text-link {
    text-decoration: underline;
}

.policies-footer-text-link,
.policies-footer-text-link:visited {
    color: black;
}