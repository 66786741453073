.add-media-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    color: grey;
    border: 1px solid #DCDEE7;
    border-radius: 5px;
}

.add-media-button {
    font-size: 25px;
    text-align: center;
    cursor: pointer;
}