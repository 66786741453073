.animated-string-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

h1.animated-char {
    margin: 0;
    display: inline;
    font: 50px 'Rubik', sans-serif;
}

@supports (-webkit-text-stroke: 1px black) {
    h1.animated-char {
        -webkit-text-stroke: 2px black;
        color: white;
    }
}

@keyframes fade-in {
    0% {
        color: white;
    }

    100% {
        color: black;
    }
}

@keyframes fade-out {
    0% {
        color: black;
    }

    100% {
        color: white;
    }
}