.link-view-container {
    --link-view-image-dimensions: 118px;
    --link-view-content-section-background-color: #F6F7FA;
    --add-media-container-color: hsl(274, 40%, 62%);

    display: flex;
    flex-direction: column;
    gap: 10px;
}

.link-view-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.link-view-topbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.link-view-topbar-buttons-container {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 15px;
}

.link-view-topbar-back-button,
.link-view-topbar-button {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.link-view-topbar-button {
    width: fit-content;
    padding: 5px 10px;
    border: 1px solid hsl(228, 23%, 60%);
    border-radius: 4px;
    color: hsl(231, 9%, 22%);
}

.link-view-topbar-button:active {
    background-color: hsl(0, 0%, 90%);
}

.link-view-topbar-button-image {
    width: 20px;
    height: 20px;
}

.link-view-topbar-back-button-image {
    width: 25px;
    height: 25px;
}

.link-view-topbar-button-text {
    font-size: 14px;
    font-weight: 300;
}

.link-view-topbar-back-button {
    color: black;
    width: fit-content;
}

.link-view-topbar-back-button-text {
    font-weight: 300;
}

.link-view-topbar-back-button-container,
.link-view-topbar-center-title,
.link-view-topbar-buttons-container {
    width: 33.3%;
}

.link-view-topbar-center-title {
    text-align: center;
}

.link-view-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.link-view-details-container,
.link-view-preview-container {
    padding: 20px;
}

.link-view-details-container {
    width: 65%;
    height: 100%;
    background-color: var(--link-view-content-section-background-color);
}

.link-view-preview-container {
    width: 33%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: var(--link-view-content-section-background-color);
}

.mobile-link-view-topbar-preview-button {
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    text-align: end;
}

.mobile-link-view-mobile-preview-close-button {
    width: 25px;
    height: 25px;
}