.colored-add-media-container {
    width: 100%;
    aspect-ratio: 1;
    flex-shrink: 0;
    position: relative;
    border: 1px solid var(--add-media-container-color);
    cursor: pointer;
}

.colored-add-media-button-inner-container {
    width: 95%;
    height: 95%;
    border: 1px dotted var(--add-media-container-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2.5%;
}

.colored-add-media-button-plus,
.colored-add-media-button-text {
    color: var(--add-media-container-color);
}

.colored-add-media-button-plus {
    font-weight: 200;
}