.approval-message-container {
    position: fixed;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: #1e2022;
    border-radius: 5px;
    padding: 10px;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: fit-content;
    translate: 0 0;
    transition-property: translate;
    transition-duration: 250ms;
    transition-timing-function: ease-out;
}

.approval-message-container {
    bottom: -40px;
    top: unset;
}

.approval-message-container.up {
    top: -40px;
    bottom: unset;
}

.approval-message-container.up.show {
    translate: 0 60px;
}

.approval-message-container.show {
    translate: 0 -60px;
}

.approval-message-text {
    color: white;
    font-size: 15px;
}

.approval-message-close-button {
    width: 12px;
    height: 12px;
    cursor: pointer;
}

.approval-message-close-button > path {
    stroke: grey;
}