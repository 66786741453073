.create-link-section-container {
    width: 60%;
    max-width: 600px;
    height: 100%;
    margin: auto;
}

.create-link-section-add-image-phase-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
}

.create-link-section-image-edit-image-frame {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 50;
}

.create-link-section-image-container {
    width: 100%;
}

.create-link-section-image-container:hover .create-link-section-add-image {
    opacity: 0.5;
}

.create-link-section-image-container:hover .create-link-section-image-edit-image-frame {
    display: block;
    cursor: pointer;
}

.create-link-section-add-image-phase-button {
    width: 100%;
    padding: 10px 20px;
    color: white;
    background-color: black;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    cursor: pointer;
}

.create-link-section-add-image-phase-button.disabled {
    opacity: 0.5;
    cursor: none;
    pointer-events: none;
}

.create-link-section-add-image-phase-button:active {
    background-color: hsl(0, 0%, 20%);
}

.create-link-section-add-image {
    width: 100%;
    aspect-ratio: 1;
}