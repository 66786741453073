.terms-and-conditions-page-container {
    --terms-and-conditions-page-back-button-container-height: 60px;

    padding: 0 25px 25px 25px;
}

.terms-and-conditions-page-content {
    padding-top: var(--terms-and-conditions-page-back-button-container-height);
}

.terms-and-conditions-page-title {
    text-align: center;
    font-size: 30px;
    margin-bottom: 15px;
}

.terms-and-conditions-page-subtitle {
    text-align: start;
    font-size: 18px;
    font-weight: 500;
    margin: 15px 0;
}

.terms-and-conditions-page-list-item-header {
    font-style: italic;
    font-weight: 450;
}

.terms-and-conditions-page-back-button-container {
    width: 100%;
    height: var(--terms-and-conditions-page-back-button-container-height);
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 40;
    display: flex;
    align-items: center;
}

.terms-and-conditions-page-back-button {
    width: 25px;
    height: 25px;
    margin-left: 20px;
    cursor: pointer;
}