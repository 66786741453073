.mobile-welcome-page-section-container,
.welcome-page-section-container {
    min-height: calc(100vh - var(--welcome-topbar-height));
    display: flex;
    justify-content: center;
    align-items: center;
}

.welcome-page-section-container {
    min-width: 1100px;
}

.mobile-welcome-page-section-container {
    max-width: 100vw;
    min-height: calc(100vh - var(--mobile-welcome-topbar-height));
}

.welcome-page-section-container:nth-child(2n) > .welcome-page-section-content {
    flex-direction: row-reverse;
}

.mobile-welcome-page-section-container:nth-child(2n) > .mobile-welcome-page-section-content,
.mobile-welcome-page-section-content {
    flex-direction: column;
    min-height: 700px;
}

.welcome-page-section-content {
    height: 700px;
    max-width: 1600px;
}

.mobile-welcome-page-section-content {
    max-width: 440px;
}

.mobile-welcome-page-section-content,
.welcome-page-section-content {
    display: flex;
    align-items: center;
    gap: 35px;
    width: 80%;
}

.mobile-welcome-page-section-content-left {
    padding: 10px 0;
}

.welcome-page-section-side {
    padding: 20px;
}

.welcome-page-section-side {
    flex: 1 1;
}

.mobile-welcome-page-section-content-right {
    flex: 1 0;
    min-height: 300px;
}

.welcome-page-section-content-right {
    height: 100%;
}

.mobile-welcome-page-section-content-right,
.welcome-page-section-content-right {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}