.link-products-slider-item-container {
    display: inline-flex;
    flex-direction: column;
    gap: 5px;
    height: 120px;
    width: 90px;
    margin: 0 2.5px;
}

.link-products-slider-item-image {
    flex-grow: 15;
}

.link-products-slider-item-title {
    text-align: center;
    font-size: 9px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: calc(100% - 10px);
    margin: 0 auto;
}

.link-products-slider-item-container:first-child {
    margin-inline-start: 5px;
}

.link-products-slider-item-container:last-child {
    margin-inline-end: 5px;
}