#root {
    height: 100%;
    font-family: 'Rubik', 'sans serif';
}

body {
    --topbar-height: 70px;
    --dashboard-topbar-height: 70px;
    --welcome-topbar-height: 70px;
    --mobile-welcome-topbar-height: 50px;
    --welcome-buttons-theme-color: #C8C8C8;
    --mobile-dashboard-topbar-titles-section: 60px;
    --mobile-dashboard-topbar-height: calc(var(--mobile-dashboard-topbar-titles-section) + 70px);
    --login-signup-button-background-color: hsl(198, 93%, 48%);
    --dashboard-topbar-initials-circle-background-color: #2e4663;
    --link-item-more-options-menu-background-color: hsl(0, 0%, 90%);
    --link-item-more-options-menu-border-radius: 10px;
    --link-item-height: 350px;
    --mobile-link-item-height: 285px;
    --dashboard-user-menu-text-color: #7a7d74;
    --subsection-title-color: #6B718E;
    
    height: 100dvh;
    padding: 0;
    margin: 0;
    line-height: normal !important;

    /* Used to prevent text selection/focus in mobile devices */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

/* Used for styling all disabled inputs */
input:disabled {
    color: #8a8a8a;
}

input, textarea {
    font-size: 16px !important;
}

textarea {
    resize: none !important;
}

a {
    text-decoration: none;
    -webkit-user-select: none !important;
}

a:hover {
    text-decoration: none !important;
}

.top-bar-page {
    padding-top: var(--topbar-height);
    height: calc(100% - var(--topbar-height));
}

/* Used for styling the authentication pages' titles */
.auth-page-title {
    font-size: 37px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    margin: 0 auto 30px auto;
}

/* Used for styling the titles of the subsections inside sections in Appearance & Store Settings pages */
.settings-pages-subsection-title {
    font-size: 13px;
    color: var(--subsection-title-color);
    text-align: start;
    width: 100%;
}