.store-settings-page-container {
    margin: 20px;
    display: flex;
    flex-direction: column;
    padding-bottom: 100px;
}

.mobile-store-settings-page-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.store-settings-page-title {
    text-align: start;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
}

.store-settings-page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.store-settings-page-store-details-section-store-name-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.store-settings-page-store-details-section-store-name-value-container {
    background-color: var(--subsection-input-background-color);
    padding: 7px 11px;
    
}

.store-settings-page-store-details-section-store-name-value {
    color: #525876;
    font-size: 15px;
    font-weight: 500;
}

.store-settings-page-store-details-section-update-logo-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 50px;
}

.store-settings-page-store-details-section-update-logo-add-logo-container {
    width: 65%;
    aspect-ratio: 4 / 1;
}

.mobile-store-settings-page-store-details-section-update-logo-add-logo-container {
    width: 100%;
    aspect-ratio: 4 / 1;
}

.store-settings-page-store-details-section-update-logo-image-container {
    width: 100%;
    height: 100%;
}

.store-settings-page-store-details-section-update-logo-button,
.store-settings-page-store-details-section-remove-button {
    width: 100%;
}

.store-settings-page-store-details-section-update-logo-button,
.store-settings-page-shop-website-section-save-button,
.mobile-store-settings-page-shop-website-section-save-button,
.store-settings-page-shipping-and-returns-section-save-button,
.store-settings-page-link-style-section-save-button,
.mobile-store-settings-page-link-style-section-save-button {
    color: #1F2535;
    border: 1px solid #1F2535;
}

.mobile-store-settings-page-shipping-and-returns-section-save-button {
    width: 100%;
    color: #1F2535;
    border: 1px solid #1F2535;
}

.store-settings-page-store-details-section-remove-button {
    color: #EE2D2D;
    border: 1px solid #EE2D2D;
}

.store-settings-page-store-details-section-update-logo-restriction {
    font-size: 12px;
    color: var(--subsection-title-color);
    white-space: pre-line;
}

.store-settings-page-store-details-section-update-logo-buttons-container {
    display: flex;
    justify-content: space-between;
    align-self: start;
    gap: 10px;
    width: 100%;
}

.store-settings-page-store-details-section {
    gap: 20px;
}

.store-settings-page-shop-website-section-shop-url-input-container,
.store-settings-page-shipping-and-returns-section-shop-url-input-container {
    background-color: var(--subsection-input-background-color);
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 10px;
    width: 100%;
    font-weight: 500;
    color: var(--subsection-title-color);
}

.store-settings-page-shop-website-section-shop-url-input,
.store-settings-page-shipping-and-returns-section-shop-url-input {
    border: none;
    background-color: transparent;
    width: 100%;
    outline: none;
    font-weight: 500;
    color: var(--subsection-title-color);
}

.store-settings-page-shop-website-section-save-button.disabled,
.mobile-store-settings-page-shop-website-section-save-button.disabled,
.store-settings-page-shipping-and-returns-section-save-button.disabled,
.store-settings-page-store-details-section-remove-button.disabled,
.mobile-store-settings-page-shipping-and-returns-section-save-button.disabled,
.store-settings-page-link-style-section-save-button.disabled,
.mobile-store-settings-page-link-style-section-save-button.disabled {
    opacity: 0.3;
    cursor: none;
    pointer-events: none;
}

.store-settings-page-shop-website-section-save-button,
.store-settings-page-shipping-and-returns-section-save-button,
.store-settings-page-link-style-section-save-button {
    min-width: 240px;
    align-self: end;
}

.store-settings-page-shop-website-section-save-button:active,
.mobile-store-settings-page-shop-website-section-save-button:active,
.store-settings-page-shipping-and-returns-section-save-button:active,
.store-settings-page-store-details-section-remove-button:active,
.store-settings-page-store-details-section-update-logo-button:active,
.mobile-store-settings-page-shipping-and-returns-section-save-button:active,
.store-settings-page-link-style-section-save-button:active,
.mobile-store-settings-page-link-style-section-save-button:active {
    background-color: hsl(0, 0%, 90%);
}

.store-settings-page-store-details-section-update-logo-add-logo-restriction-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
    width: 100%;
}

.store-settings-page-store-details-section-store-logo-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.store-settings-page-shop-website-section-save-button,
.mobile-store-settings-page-shop-website-section-save-button,
.store-settings-page-shipping-and-returns-section-save-button,
.mobile-store-settings-page-shipping-and-returns-section-save-button,
.store-settings-page-store-details-section-remove-button,
.store-settings-page-store-details-section-update-logo-button,
.store-settings-page-link-style-section-save-button,
.mobile-store-settings-page-link-style-section-save-button {
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 5px;
    background-color: white;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    text-align: center;
    cursor: pointer;
}

.store-settings-discount-style-container,
.store-settings-checkout-button-style-container {
    display: flex;
    width: 100%;
}

.store-settings-discount-style-fields,
.store-settings-checkout-button-style-fields {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.store-settings-discount-preview,
.store-settings-checkout-button-preview {
    width: 100%;
    display: flex;
    justify-content: center;
}

.store-settings-discount-preview-frame,
.store-settings-checkout-button-preview-frame {
    width: 75%;
    height: 75%;
}

.store-settings-discount-preview-element,
.store-settings-checkout-button-preview-element {
    margin: 0 auto;
    width: fit-content;
}

.store-settings-checkout-button-preview-element {
    color: white;
    font-size: 15px;
    border: 1px solid;
    padding: 10px 20px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.store-settings-checkout-button-preview-element-dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
}

.store-settings-discount-preview-element {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.store-settings-discount-preview-discount-original-price {
    font-size: 15px;
    color: #d0d0d0;
    text-decoration: line-through;
}

.store-settings-discount-preview-discount-price {
    font-size: 15px;
    color: black;
}

.store-settings-discount-preview-discount-price-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.store-settings-discount-preview-discount-percentage {
    padding: 3px;
    font-size: 12px;
    font-weight: 300;
}