.store-settings-option-picker-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.store-settings-option-picker-option {
    background-color: white;
    border: 3px solid #cccccc;
    width: 60px;
    aspect-ratio: 1.3;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.store-settings-option-picker-option.selected {
    border-color: #2c6ecb;
}