.pricing-plan-container {
    display: inline-flex;
    flex-direction: column;
    gap: 15px;
    width: 265px;
    min-width: 265px;
    height: 500px;
    border-radius: 10px;
    border: 1px solid hsl(0, 0%, 80%);
}

.pricing-plan-color-bar {
    height: 20px;
    width: 100%;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.pricing-plan-content {
    padding: 15px 25px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: start;
    position: relative;
    height: 100%;
}

.pricing-plan-name {
    text-transform: capitalize;
    font-weight: 800;
}

.pricing-plan-pricing-method {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 20px;
}

.pricing-plan-price {
    font-size: 30px;
}

.pricing-plan-separator {
    width: 95%;
    height: 2px;
    background-color: #cccccc;
}

.pricing-plan-active-plan-label,
.pricing-plan-unavailable-plan-label {
    padding: 10px;
    width: fit-content;
    margin: auto;
}

.pricing-plan-active-plan-label {
    background-color: black;
    color: white;
}

.pricing-plan-unavailable-plan-label {
    background-color: #cccccc;
    color: white;
}

.pricing-plan-bottom-section {
    position: absolute;
    bottom: 20px;
    left: 0;
    text-align: center;
    width: 100%;
}

.pricing-plan-bottom-button {
    padding: 10px 15px;
    background-color: green;
    color: white;
    width: fit-content;
    margin: auto;
    cursor: pointer;
}

.pricing-plan-feature-row {
    font-size: 15px;
}

.pricing-plan-feature-v {
    width: 20px;
    height: 20px;
}

.pricing-plan-feature {
    word-wrap: break-word;
    margin-inline-start: 10px;
    display: inline;
}