.settings-input-container {
    background-color: var(--subsection-input-background-color);
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 10px;
    width: 100%;
    font-weight: 500;
    color: var(--subsection-title-color);
    border: 1px solid #DCDEE7;
    border-radius: 5px;
}

.settings-input {
    border: none;
    background-color: transparent;
    width: 100%;
    outline: none;
    font-weight: 500;
    color: var(--subsection-title-color);
}

.settings-input::placeholder {
    color: #d8d8d8;
}