.preview-link-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.preview-link-section-link-details {
    width: 100%;
}

.preview-link-section-link-image {
    width: var(--link-view-image-dimensions);
    aspect-ratio: 1;
    margin-inline-end: 20px;
    float: left;
}

.preview-link-section-link-description {
    font-size: 13px;
    color: hsl(228, 23%, 60%);
    height: 100%;
    padding: 13px 0;
}

.preview-link-section-link-analytics {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
}

.preview-link-section-link-analytics-data {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
    width: 100%;
}

.mobile-preview-link-section-buttons-section {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
}

.mobile-preview-link-section-button-image {
    width: 15px;
    height: 15px;
}

.mobile-preview-link-section-button-text {
    font-size: 13px;
}

.mobile-preview-link-section-button {
    width: fit-content;
    padding: 5px 7px;
    border: 1.5px solid hsl(228, 23%, 60%);
    border-radius: 4px;
    color: hsl(231, 9%, 22%);
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}