.edit-link-section-add-products-dialog-content {
    --add-products-dialog-topbar-height: 90px;
    --add-products-dialog-product-list-header-height: 30px;
    --add-products-dialog-product-list-height: 375px;

    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    position: relative;
}

.edit-link-section-add-products-dialog-no-results-found-container,
.edit-link-section-add-products-dialog-loader-container {
    position: absolute;
    left: 0;
    top: calc(var(--add-products-dialog-topbar-height) + var(--add-products-dialog-product-list-header-height) + 10px);
    width: 100%;
    height: var(--add-products-dialog-product-list-height);
}

.edit-link-section-add-products-dialog-no-results-found-container {
    background-color: white;
    z-index: 50;
}

.edit-link-section-add-products-dialog-no-results-found-content {
    position: absolute;
    inset: 0;
    margin: auto;
    width: fit-content;
    height: fit-content;
}

.edit-link-section-add-products-dialog-no-results-found-text {
    font-size: 20px;
    color: #B0B0B0;
    text-align: center;
}

.edit-link-section-add-products-dialog-topbar {
    display: flex;
    justify-content: space-between;
    padding: 0 25px;
    height: var(--add-products-dialog-topbar-height);
}

.edit-link-section-add-products-dialog-topbar-title-container {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 15px 0;
}

.edit-link-section-add-products-dialog-topbar-title {
    font-size: 18px;
    font-weight: 500;
}

.edit-link-section-add-products-dialog-topbar-selected-products-count {
    font-size: 14px;
    color: #acaebf;
}

.edit-link-section-add-products-dialog-topbar-search-box {
    padding: 5px 10px;
    border: 1px solid hsl(225, 18%, 90%);
    display: flex;
    align-items: center;
    gap: 10px;
    width: 300px;
    height: 50px;
    margin: auto 0;
    border-radius: 3px;
}

.edit-link-section-add-products-dialog-topbar-search-box-input {
    border: none;
    outline: none;
    width: 100%;
}

.edit-link-section-add-products-dialog-topbar-search-box-input::placeholder {
    font-size: 15px;
    font-weight: 500;
    color: #b8bcd2;
}

.edit-link-section-add-products-dialog-done-button-container {
    width: 100%;
    display: flex;
    justify-content: end;
    padding: 15px;
}

.edit-link-section-add-products-dialog-done-button {
    padding: 10px 20px;
    background-color: black;
    color: white;
    text-transform: uppercase;
    text-align: center;
    width: 200px;
    font-weight: 300;
    letter-spacing: 2px;
    font-size: 14px;
    cursor: pointer;
}

.products-table-header {
    font-size: 13px;
    font-weight: 300;
    text-align: start;
    padding: 7px 0;
}

table, tbody, thead {
    display: block;
}

thead {
    background-color: #eff0f3;
}

tbody {
    overflow-y: auto;
    height: var(--add-products-dialog-product-list-height);
    display: block;
}

thead > tr {
    display: flex;
    width: 100%;
    padding: 0 15px;
    height: var(--add-products-dialog-product-list-header-height);
}

tbody > tr {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    height: 75px;
    position: relative;
    cursor: pointer;
}

tbody > tr::after {
    content: "";
    display: block;
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: hsl(0, 0%, 92%);
    bottom: 0;
    left: 0;
}

td {
    font-size: 14px;
}

tr {
    display: table;
}

.edit-link-section-add-products-dialog-products-table-header-image,
.edit-link-section-add-products-dialog-products-table-cell-image {
    width: 6%;
}

.edit-link-section-add-products-dialog-products-table-header-product-name,
.edit-link-section-add-products-dialog-products-table-cell-product-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.edit-link-section-add-products-dialog-products-table-header-button,
.edit-link-section-add-products-dialog-products-table-cell-button {
    width: 16%;
}

.edit-link-section-add-products-dialog-product-image {
    width: 55px;
    aspect-ratio: 1;
}

.edit-link-section-add-products-dialog-products-table-cell-button {
    display: flex;
    justify-content: end;
}

.edit-link-section-add-products-dialog-product-button {
    padding: 0px 10px;
    border: 1px solid #d1d4e2;
    color: #b444e8;
    background-color: white;
    width: fit-content;
    text-align: center;
    border-radius: 3px;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    align-self: end;
}

.edit-link-section-add-products-dialog-product-button:active {
    background-color: hsl(0, 0%, 90%);
}

.edit-link-section-add-products-dialog-product-button-plus {
    font-size: 21px;
    font-weight: 200;
    margin-bottom: 2px;
}

.edit-link-section-add-products-dialog-product-button.selected {
    padding: 5px 10px;
    color: #a3a8bb;
    background-color: hsl(225, 29%, 95%);
    display: block;
}

.edit-link-section-add-products-dialog-product-button.selected:active {
    background-color: hsl(225, 29%, 90%);
}