.links-page-topbar,
.mobile-links-page-topbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.links-page-container {
    overflow: hidden;
}

.links-page-topbar-store-image,
.mobile-links-page-topbar-store-image {
    width: 100px;
    aspect-ratio: 5 / 2;
}

.links-page-topbar-store-name-container,
.mobile-links-page-topbar-store-name-container,
.links-page-topbar-buttons-container,
.mobile-links-page-topbar-buttons-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.links-page-topbar-store-name,
.mobile-links-page-topbar-store-name {
    font-weight: 500;
    font-size: 17px;
}

.links-page-topbar-button,
.mobile-links-page-topbar-button {
    background-color: white;
    border: 1.5px solid black;
    cursor: pointer;
    border-radius: 4px;
    height: 35px;
}

.links-page-topbar-button:active,
.mobile-links-page-topbar-button:active {
    background-color: hsl(0, 0%, 85%);
}

.links-page-topbar-create-new-link-button {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 13px;
    padding: 10px 20px;
}

.links-page-topbar-more-options-button,
.mobile-links-page-topbar-more-options-button {
    width: 35px;
    padding: 5px;
}

.links-page-content.no-links-content,
.mobile-links-page-content.mobile-no-links-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
}

.links-page-content-links-list,
.mobile-links-page-content-links-list,
.mobile-links-page-content {
    display: grid;
    gap: 15px;
}

.mobile-links-page-content-links-list {
    height: calc(100vh - var(--mobile-dashboard-topbar-height) - 70px);
    grid-template-columns: repeat(2, minmax(0, 1fr));
    overflow: auto;
    gap: 5px;
}

.links-page-content {
    position: relative;
    overflow: auto;
    min-height: 500px;
}

.links-page-content-links-list {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    height: calc(100vh - var(--dashboard-topbar-height) - 105px);
    overflow: auto;
    margin-top: 20px;
    padding-bottom: 15px;
}

.mobile-links-page-content {
    margin-top: 0;
    height: calc(100vh - var(--mobile-dashboard-topbar-height) - 20px);
    padding: 40px 10px 0 10px;
    overflow: hidden;
}

.links-page-topbar-store-name-circle,
.mobile-links-page-topbar-store-name-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--dashboard-topbar-initials-circle-background-color);
    text-transform: uppercase;
    padding: 5px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.links-page-create-your-first-link-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.links-page-create-your-first-link-section-titles-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: center;
}

.links-page-create-your-first-link-section-image {
    width: 65px;
    height: 65px;
}

.links-page-create-your-first-link-section-title {
    font-size: 25px;
    font-weight: 300;
}

.links-page-create-your-first-link-section-subtitle {
    font-size: 15px;
    font-weight: 300;
}

.links-page-create-your-first-link-section-create-link-button {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 13px;
    padding: 10px 20px;
    background-color: white;
    border: 1.5px solid black;
    cursor: pointer;
    border-radius: 4px;
    height: 35px;
    margin-top: 20px;
}

.links-page-create-your-first-link-section-create-link-button:active {
    background-color: hsl(0, 0%, 85%);
}

.mobile-links-page-options-drawer-option {
    padding: 20px;
    text-align: center;
}

.mobile-links-page-options-drawer-option:active {
    background-color: hsl(0, 0%, 90%);
}

.mobile-links-page-topbar-search-box {
    position: fixed;
}

.mobile-links-page-topbar-search-box,
.links-page-topbar-search-box {
    padding: 5px 10px;
    border: 1px solid hsl(225, 18%, 90%);
    display: flex;
    align-items: center;
    gap: 10px;
    height: 35px;
    margin: auto 0;
    border-radius: 3px;
}

.links-page-topbar-search-box-input {
    border: none;
    outline: none;
    width: 100%;
}

.links-page-topbar-search-box-input::placeholder {
    font-size: 15px;
    font-weight: 500;
    color: #b8bcd2;
}

.links-page-topbar-search-box-image {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.links-page-topbar-search-box-image.close {
    cursor: pointer;
}

.links-page-no-results-found-container {
    top: calc(var(--dashboard-topbar-height) + 10px);
}

.links-page-no-results-found-container,
.mobile-links-page-no-results-found-container {
    position: absolute;
    left: 0;
    width: 100%;
    height: 30dvh;
    max-height: 350px;
    background-color: white;
    z-index: 50;
}

.mobile-links-page-no-results-found-container {
    top: calc(var(--mobile-dashboard-topbar-height) + 35px + 20px);
}

.links-page-no-results-found-content {
    position: absolute;
    inset: 0;
    margin: auto;
    width: fit-content;
    height: fit-content;
}

.links-page-no-results-found-text {
    font-size: 20px;
    color: #B0B0B0;
    text-align: center;
}