.account-verification-page-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.account-verification-central-container {
    position: relative;
    width: 100%;
    height: 90px;
}

.account-verification-loader {
    position: absolute;
    inset: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.account-verification-result-indication {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transition: scale 600ms ease-out;
    scale: 0;
    width: 100%;
    height: 100%;
}

.account-verification-result-indication.has-result {
    scale: 1;
}

.account-verification-verified-title {
    white-space: pre-line;
}

.account-verification-verified-title,
.account-verification-not-verified-title,
.account-verification-loader-title {
    font-size: 21px;
    color: black;
    text-align: center;
}

.account-verification-indication {
    width: 50px;
    height: 50px;
}

.account-verification-not-verified-indication {
    fill: #e31c1c;
}

.account-verification-verified-indication {
    fill: green;
}