.welcome-page-footer {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
    width: 85%;
    margin: 0 auto;
    background-color: white;
    border-radius: 20px;
}

.welcome-page-footer-sections-container {
    display: flex;
    align-items: center;
    gap: 50px;
}


.mobile-welcome-page-footer-section,
.welcome-page-footer-section {
    display: flex;
    flex-direction: column;
    align-items: start;
    align-self: start;
}

.welcome-page-footer-section {
    gap: 15px;
}

.mobile-welcome-page-footer-section {
    gap: 0px;
}

.welcome-page-footer-section-title {
    font-size: 18px;
    font-weight: 500;
}

.welcome-page-footer-section-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.welcome-page-footer-section-button {
    border-radius: 5px;
    cursor: pointer;
}

.welcome-page-footer-section-button:hover {
    background-color: hsl(0, 0%, 90%);
}

.welcome-page-footer-section-title,
.welcome-page-footer-section-button,
.welcome-page-footer-section-option {
    padding: 10px;
}

.welcome-page-footer-section-button,
.welcome-page-footer-section-option {
    color: #676b5f;
}

.welcome-page-footer-section-send-mail-option,
.welcome-page-footer-section-send-mail-option:hover {
    text-decoration: underline;
    color: #676b5f;
}

.welcome-page-footer-buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.welcome-page-footer-buttons-box {
    display: flex;
    align-items: center;
    gap: 10px;
}

.welcome-page-footer-button {
    padding: 10px 15px;
    cursor: pointer;
}

.welcome-page-footer-signin-button,
.welcome-page-footer-signup-button {
    border-radius: 5px;
    cursor: pointer;
}