.mobile-link-preview-simulator-container {
    width: 100%;
    height: 100%;
    border-radius: 25px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.mobile-link-preview-simulator-topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    height: 10%;
    padding: 0 15px;
}

.mobile-link-preview-simulator-topbar-image {
    width: 20px;
    height: 20px;
}

.mobile-link-preview-simulator-main-image-placeholder-container {
    padding: 10px;
}

.mobile-link-preview-simulator-mobile-simulator-topbar-image {
    height: 90%;
    aspect-ratio: 3.5;
}

.mobile-link-preview-simulator-topbar-store-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.mobile-link-preview-simulator-main-image {
    width: 100%;
    height: 70%;
}

.mobile-link-preview-simulator-content {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 40;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mobile-link-preview-simulator-upper-container {
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: column;
}

.mobile-link-preview-simulator-products-slider-container {
    height: fit-content;
    margin-top: 10px;
}

.mobile-link-preview-simulator-link-expired-frame {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    position: absolute;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
}

.mobile-link-preview-simulator-link-expired-text {
    background-color: transparent;
    color: #000000be;
    padding: 5px 10px;
    font-size: 30px;
    text-align: center;
}