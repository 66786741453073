.login-page-container {
    height: 100vh;
}

.login-page-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    width: 100%;
}

.login-page-fields-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.input-field {
    border: none;
    font-size: 15px;
    background-color: transparent;
    height: 100%;
    border-radius: inherit;
    width: 100%;
    outline: none;
}

.input-field-container {
    height: 50px;
    background-color: #e2e2e2;
    border-radius: 7px;
    padding: 0 10px;
}

.login-page-fields-form-password-input-container {
    display: flex;
    align-items: center;
}

.login-page-password-field-show-hide-password-image {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.login-page-login-button {
    width: 100%;
    text-align: center;
    background-color: var(--login-signup-button-background-color);
    color: white;
    padding: 15px 0;
    border-radius: 30px;
    cursor: pointer;
    pointer-events: all;
}

.login-page-login-button:active {
    background-color: hsl(198, 93%, 53%);
}

.login-page-login-button.disabled {
    background-color: #cccccc7f;
    color: #cccccc;
    cursor: none;
    pointer-events: none;
}

.login-page-forgot-password-button {
    font-size: 15px;
    text-decoration: underline;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.login-page-dont-have-account-section {
    font-size: 15px;
    width: fit-content;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 5px;
}

.login-page-dont-have-account-sign-up-button {
    cursor: pointer;
    text-decoration: underline;
}

.login-page-login-error-message {
    font-size: 14px;
    padding: 10px;
    background-color: #ffdce0;
    color: #d74454;
    border: 1px solid #feb1bb;
    width: 100%;
    margin: 0 auto;
}

.login-page-login-error-message-text {
    text-align: start;
}