.edit-link-section-container {
    --tooltip-bubble-width: 220px;

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.edit-link-section-link-details {
    display: flex;
    align-items: start;
    gap: 15px;
    height: fit-content;
}

.edit-link-section-link-image-container {
    width: var(--link-view-image-dimensions);
    aspect-ratio: 1;
    flex-shrink: 0;
    position: relative;
}

.edit-link-section-add-media-button {
    width: 100%;
    height: 100%;
    border: 1px solid var(--add-media-container-color);
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
    display: none;
}

.edit-link-section-add-media-button-inner-container {
    width: 95%;
    height: 95%;
    border: 1px dotted var(--add-media-container-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2.5%;
}

.edit-link-section-link-image-container:hover > .edit-link-section-link-image {
    opacity: 0.5;
}

.edit-link-section-link-image-container:hover > .edit-link-section-add-media-button {
    display: block;
}

.edit-link-section-add-media-button-plus,
.edit-link-section-add-media-button-text {
    color: var(--add-media-container-color);
}

.edit-link-section-add-media-button-plus {
    font-size: 35px;
    font-weight: 200;
}

.edit-link-section-link-image {
    width: 100%;
    height: 100%;
}

.edit-link-section-add-products-button {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 20px;
    cursor: pointer;
    background-color: white;
}

.edit-link-section-add-products-button-title {
    font-size: 18px;
    color: hsl(231, 17%, 39%);
}

.edit-link-section-add-products-button-right-section {
    display: flex;
    align-items: center;
    gap: 15px;
}

.edit-link-section-add-products-button-right-section-product-quantity {
    color: var(--add-media-container-color);
    font-size: 15px;
}

.edit-link-section-add-products-button-right-section-image {
    width: 20px;
    height: 20px;
}

.edit-link-section-link-data-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-top: 20px;
    gap: 15px;
}

.edit-link-section-link-data-form-inputs {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 15px;
}

.edit-link-section-link-data-link-visibility {
    display: flex;
    align-items: start;
    gap: 10px;
}

.edit-link-section-link-data-link-visibility-title-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
}

.edit-link-section-link-data-link-visibility-title {
    font-size: 18px;
    color: hsl(233, 27%, 25%);
}

.edit-link-section-link-data-link-visibility-subtitle-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.edit-link-section-link-data-link-visibility-subtitle {
    font-size: 14px;
    color: hsl(227, 14%, 60%);
    font-weight: 300;
    white-space: pre-line;
}

.edit-link-section-link-data-link-visibility-upgrade-button {
    font-size: 14px;
    padding: 5px 10px;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    color: black;
    cursor: pointer;
    flex: 0 0;
}

.edit-link-section-link-data-link-visibility-upgrade-button:hover {
    background-color: hsl(0, 0%, 90%);
}























.edit-link-section-button,
.mobile-edit-link-section-button {
    width: 50%;
    align-self: end;
    color: white;
    background-color: black;
    text-transform: uppercase;
    font-size: 17px;
    text-align: center;
    padding: 12px 0;
    cursor: pointer;
    pointer-events: all;
}

.edit-link-section-button.disabled,
.mobile-edit-link-section-button.disabled {
    opacity: 0.5;
    cursor: pointer;
    pointer-events: none;
}

.mobile-edit-link-section-button {
    width: 100%;
    margin-bottom: 10px;
}

.edit-link-section-row {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 15px;
    margin-top: 15px;
}

