.pricing-plans-page-title {
    font-size: 35px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    margin-bottom: 40px;
    text-transform: capitalize;
}

.pricing-plans-page-plans-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    overflow-x: auto;
    white-space: nowrap;
    padding: 0 20px;
}

@media (max-width: 1200px){
    .pricing-plans-page-plans-container {
        justify-content: start;
    }
}