
.topbar-container {
    --topbar-button-dimens: 20px;
    --topbar-image-height: 20px;
    --topbar-left-button-space: 10px;

    align-items: center;
    display: flex;
    justify-content: space-between;
    height: var(--topbar-height);
    background-color: white;
    padding: 0 20px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 70;
}

.topbar-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.topbar-button {
    width: var(--topbar-button-dimens);
    height: var(--topbar-button-dimens);
    cursor: pointer;
}

.topbar-right-button-text {
    font-size: 16px;
    text-align: start;
    color: blue;
    cursor: pointer;
    pointer-events: all;
}

.topbar-center-image {
    height: calc(var(--topbar-height) - 10px);
    aspect-ratio: 3;
}

.topbar-title {
    text-transform: uppercase;
    font-family: 'Cormorant Garamond', 'Rubik';
    font-weight: 700;
    font-size: 35px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 70%;
    overflow: hidden;
    text-align: center;
}