.analytics-time-select-container {
    border: 1px solid hsl(229, 23%, 72%);
    border-radius: 5px;
    position: relative;
    color: hsl(229, 23%, 72%);
}

.analytics-time-select-image {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    right: 5px;
}