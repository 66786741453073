
.loader-container {
    width: 100%;
    margin-top: var(--topbar-height);
    height: calc(100vh - var(--topbar-height));
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 150;
    position: absolute;
    top: 0;
    left: 0;
    font-family: 'Rubik', 'sans-serif';
}